export const environment = {
  production: true,
  url: "https://dolistdevapi.appskeeper.in/rcc/api/",
  tokenKey: "do-list-admin-token",
  S3: {
    bucketName: "app-development",
    region: "us-east-1",
    acl: "public-read",
    AWS_ACCESS_KEY: "AKIAQUD4MO3Q633CRQZH",
    AWS_SECRET_KEY: "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",
  },
};
