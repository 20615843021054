import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, TimeoutError } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { UtilityService } from '../modules/shared/services/utility.service';
import { LoaderService } from '../modules/shared/services/loader.service';
import { LOGIN } from '../constant/routes';

export enum BASIC_AUTH {
    username = 'rcc',
    password = 'rcc@123'
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    token: string;
    accessToken = false;
    basicAuth: string;
  
    constructor(
      private router: Router,
      private utilityService: UtilityService,
      private loaderService: LoaderService
    ) {
      this.basicAuth = 'Basic' + ' ' + btoa(`${BASIC_AUTH.username}:${BASIC_AUTH.password}`);
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.utilityService.getAuthToken()) {
        this.token = this.utilityService.getAuthToken();
        this.accessToken = true;
      }
  
      const headers = {
        //'Content-Type': 'application/json',
        'Platform': '3',
        'timezone': '0',
        'api_key': '1234',
        // 'x-access-token': 'Bearer' + ' ' + this.token,
        Authorization: this.basicAuth,
        // 'Accept-Language': 'en'
      };
      if (this.accessToken === false) {
        delete headers['x-access-token'];
      }
      const token = this.utilityService.getAuthToken();
      if (token) {
        headers['authorization'] = 'Bearer ' + this.token;
      }

      request = request.clone({
        setHeaders: headers
      });
  
  
      return next.handle(request).pipe(
        timeout(30000),
        tap(
          (data) => {
            if (data instanceof HttpResponse) {
              this.loaderService.loader.next(false);
            }
          },
          (err: any) => {
            this.loaderService.loader.next(false);
            if (err instanceof HttpErrorResponse) {
              this.utilityService.errorAlert(err);
              if (err.status === 401 || err.status === 423 || err.error.responseType == 'UNAUTHORIZED') {
                this.utilityService.clearStorage();
                this.router.navigate(['auth/login']);
              }
            } else if (err instanceof TimeoutError) {
              this.utilityService.errorAlert('Request taking too long to respond. Try after some time');
            }
          }
        ));
    }
  }
  