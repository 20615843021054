import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "../../../environments/environment";
import { LoaderService } from "src/app/modules/shared/services/loader.service";
// import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  bucket: S3;
  constructor(private _loaderService: LoaderService) {
    this.bucket = new S3({
      accessKeyId: environment.S3.AWS_ACCESS_KEY,
      secretAccessKey: environment.S3.AWS_SECRET_KEY,
      region: environment.S3.region,
    });
  }

  async uploadFile(fileToUpload: File) {
    try {
      const params = {
        Bucket: environment.S3.bucketName,
        Key: fileToUpload.name || new Date().getTime() + ".png",
        Body: fileToUpload,
        ACL: environment.S3.acl,
      };
      this._loaderService.loader.next(true);
      return new Promise((resolve, reject) => {
        this.bucket
          .upload(params, (err, data) => {
            if (err) {
              reject(err);
              this._loaderService.loader.next(false);
              return false;
            } else {
              this._loaderService.loader.next(false);
              resolve(data.Location);
            }
          })
          .on("httpUploadProgress", (progress) => {});
      });
    } catch (err) {
      this._loaderService.loader.next(false);
      console.error(err.message);
    }
  }
}
